.scrollbars--dark {
    -ms-overflow-style: auto;
    /* Foreground, Background */
    scrollbar-color: #fff #172535;
}
.scrollbars--dark::-webkit-scrollbar {
    width: 5px; /* Mostly for vertical scrollbars */
    height: 5px; /* Mostly for horizontal scrollbars */
}
.scrollbars--dark::-webkit-scrollbar-thumb {
    /* Foreground */
    @apply bg-white rounded-sm;
}
.scrollbars--dark::-webkit-scrollbar-track {
    /* Background */
    @apply bg-midnight;
}

.scrollbars--light {
    -ms-overflow-style: auto;
    /* Foreground, Background */
    scrollbar-color: #fff #f5f2ed;
}
.scrollbars--light::-webkit-scrollbar {
    width: 5px; /* Mostly for vertical scrollbars */
    height: 5px; /* Mostly for horizontal scrollbars */
}
.scrollbars--light::-webkit-scrollbar-thumb {
    /* Foreground */
    @apply bg-white rounded-sm;
}
.scrollbars--light::-webkit-scrollbar-track {
    /* Background */
    @apply bg-ivory;
}

/* Gradients on the end of scrollbars for mobile */
.scrolling-container {
    @apply max-w-none md:max-w-xs lg:max-w-lg xl:max-w-3xl 2xl:max-w-5xl;
}

.scrolling-inner {
    @apply relative;
    &:after {
        background: -webkit-radial-gradient(right ellipse, rgba(23, 37, 53, 0.8) 0%, rgba(0, 0, 0, 0) 60%);
        background: radial-gradient(ellipse at right, rgba(23, 37, 53, 0.8) 0%, rgba(0, 0, 0, 0) 60%);
        right: 0;
    }
    &:before {
        background: -webkit-radial-gradient(left ellipse, rgba(23, 37, 53, 0.8) 0%, rgba(0, 0, 0, 0) 60%);
        background: radial-gradient(ellipse at left, rgba(23, 37, 53, 0.8) 0%, rgba(0, 0, 0, 0) 60%);
        left: 0;
    }
    &:before,
    &:after {
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: 15px 200%;
        background-position: 100% center;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 15px;
        z-index: 500;
    }
}

.scrolling {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    border-collapse: collapse;
    border-spacing: 0;
    -webkit-overflow-scrolling: touch;
}
