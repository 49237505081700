@layer components {
    button {
        /* @apply focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2; */
    }
    .btn {
        @apply self-start block w-full px-4 pt-3 pb-2.5 font-sans font-bold text-center transition-all rounded-sm cursor-pointer sm:max-w-sm text-md sm:w-auto whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2;

        &:disabled {
            @apply opacity-50 cursor-not-allowed focus:outline-none focus-visible:ring-0;
        }

        /* Better hover prevents it from applying hovering styling on ipad and iphone */
        &--primary {
            @apply border text-blue border-yellow bg-yellow focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 betterhover:hover:bg-yellow-dark betterhover:hover:border-yellow-dark;
            &:active {
                @apply bg-yellow border-yellow;
            }
            &:disabled:hover,
            &:visited,
            &:visited:hover,
            &:active:hover {
                @apply bg-yellow border-yellow;
            }
        }
        &--dark {
            @apply border text-yellow border-midnight bg-midnight focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 betterhover:hover:bg-blue betterhover:hover:border-blue;
            &:hover:active,
            &:active {
                @apply bg-blue-dark border-blue-dark;
            }
            &:disabled:hover {
                @apply bg-midnight border-midnight;
            }
        }
        &--outline-light {
            @apply border text-yellow border-yellow focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 betterhover:hover:border-yellow-dark betterhover:hover:text-yellow-dark;
            &:hover:active,
            &:active {
                @apply bg-yellow-md border-yellow-md;
            }
        }
        &--outline-dark {
            @apply border text-blue border-blue focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 betterhover:hover:text-blue-400 betterhover:hover:border-blue-400;
            &:hover:active,
            &:active {
                @apply bg-blue-dark border-blue-dark;
            }
        }
        &--full {
            @apply w-full;
        }
    }
}
