.html-block--light .wordDefine,
.html-block--light a,
.wordDefine {
    background-image: linear-gradient(to bottom, theme(colors.midnight) 75%, theme(colors.midnight) 75%);
    background-repeat: repeat-x;
    background-size: 1px 1px;
    background-position: 0 98%;
    @apply font-bold transition-all duration-300 cursor-pointer;
    &:hover,
    &:focus,
    &:active {
        background-image: linear-gradient(to bottom, theme(colors.blue.DEFAULT) 75%, theme(colors.blue.DEFAULT) 75%);
        @apply text-blue;
    }
    &:visited {
        @apply text-midnight;
    }
}
.html-block--dark .wordDefine,
.html-block--dark a {
    background-image: linear-gradient(to bottom, theme(colors.white) 75%, theme(colors.white) 75%);
    background-repeat: repeat-x;
    background-size: 1px 1px;
    background-position: 0 98%;
    @apply transition-all duration-300 cursor-pointer;
    &:hover,
    &:focus,
    &:active {
        background-image: linear-gradient(
            to bottom,
            theme(colors.yellow.DEFAULT) 75%,
            theme(colors.yellow.DEFAULT) 75%
        );
        @apply text-yellow;
    }
    &:visited {
        @apply text-white;
    }
}

.app-offline {
    .html-block--light a,
    .html-block--dark a {
        background-image: none;
        @apply cursor-text;
        &:hover,
        &:focus,
        &:active,
        &:visited {
            background-image: none;
        }
    }
}

.app-offline {
    .html-block--light a {
        &:hover,
        &:focus,
        &:active,
        &:visited {
            @apply text-black;
        }
    }
}
.app-offline {
    .html-block--dark a {
        &:hover,
        &:focus,
        &:active,
        &:visited {
            @apply text-white;
        }
    }
}

.infoPopup--glossary {
    @apply text-white bg-midnight p-4 h-auto w-[320px];

    p {
        @apply text-sm;
    }

    .button {
        @apply inline-flex px-4 h-[40px] items-center mt-5 font-bold transition-all duration-300 bg-white rounded-sm text-base-plus text-blue;

        &:hover,
        &:focus {
            /* background: theme(colors.blue.300); */
            @apply text-midnight bg-ivory;
        }

        svg {
            position: relative;
            margin-left: 18px;
        }

        path {
            @apply fill-blue;
        }
    }
}
