@import 'tippy.js/animations/scale-subtle.css';
@import 'tippy.js/dist/tippy.css';
@import './base.css';
@import './components.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Appears to only work on desktop */
/* Browser only */
@media all and (display-mode: browser) {
    .mode--standalone,
    .mode--standalone-flex {
        display: none;
    }
    .mode--browser {
        display: block;
    }
}

/* PWA only */
@media all and ((display-mode: standalone) or (display-mode: fullscreen) or (display-mode: minimal-ui)) {
    .mode--standalone {
        display: block;
    }
    .mode--standalone-flex {
        display: flex;
    }
    .mode--browser {
        display: none;
    }
}

@layer base {
    /* attempt to fix not scrolling on safari mobile on prod */
    html,
    body {
        @apply w-full h-full print:h-auto;
    }

    .h-screen {
        height: -webkit-fill-available;
    }

    @font-face {
        font-family: 'Mercury';
        src: url('../../../libs/assets/src/lib/fonts/mercury/MercuryDisplay-Roman_Web.woff');
        font-style: normal;
        font-weight: 400;
        font-display: swap;
    }
    @font-face {
        font-family: 'Mercury';
        src: url('../../../libs/assets/src/lib/fonts/mercury/MercuryDisplay-Italic_Web.woff');
        font-style: italic;
        font-weight: 400;
        font-display: swap;
    }
    @font-face {
        font-family: 'Mercury';
        src: url('../../../libs/assets/src/lib/fonts/mercury/MercuryDisplay-Semibold_Web.woff');
        font-style: normal;
        font-weight: 600;
        font-display: swap;
    }
    @font-face {
        font-family: 'Avenir';
        src: url('../../../libs/assets/src/lib/fonts/avenir/AvenirLTPro-Book/AvenirLTPro-Book.woff');
        font-style: normal;
        font-weight: 300;
        font-display: swap;
    }
    @font-face {
        font-family: 'Avenir';
        src: url('../../../libs/assets/src/lib/fonts/avenir/AvenirLTPro-BookOblique/AvenirLTPro-BookOblique.woff');
        font-style: italic;
        font-weight: 400;
        font-display: swap;
    }
    @font-face {
        font-family: 'Avenir';
        src: url('../../../libs/assets/src/lib/fonts/avenir/AvenirLTPro-Heavy/AvenirLTPro-Heavy.woff');
        font-style: normal;
        font-weight: 800;
        font-display: swap;
    }
}
