.toast-container {
    @apply max-w-[600px] w-auto;
}
.toast-container--dark {
    @apply w-full text-white bg-midnight;
}

.Toastify__toast-container {
    @apply p-0;
}
.Toastify__toast {
    @apply py-3 md:p-0 md:mb-0;
}
.Toastify__toast-body {
    @apply my-[2px] mx-5;
}

.Toastify__close-button {
    @apply hidden;
}
