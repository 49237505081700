/* For hotspots */
.tippy-content {
    @apply p-0;
    z-index: 40;
}

.tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
    @apply border-b-midnight;
}
.tippy-box[data-placement^='top'] > .tippy-arrow:before {
    @apply border-t-midnight;
}

/* Tooltip for main nav menu  */
.infoPopup--menu {
    @apply relative h-auto px-2 pt-2 pb-1 rounded-sm text-midnight bg-yellow;

    &:before {
        content: '';
        position: absolute;
        left: 11px;
        top: -7px;
        width: 0;
        height: 0;
        border-width: 0 7.5px 8px 7.5px;
        border-color: transparent transparent #f2cc6a transparent;
        border-style: solid;
    }

    p {
        @apply text-sm;
    }
}
